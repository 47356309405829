<template>
  <div class="oil">
    <div class="upload-info">
      <div class="title">添加小票照片</div>
      <Upload v-on:onupload="handleSend" />
      <div class="tips">
        <p class="error" v-if="visible">
          <span>无法识别票据信息</span>
          <span>请重新拍照并上传</span>
        </p>
      </div>
    </div>

    <Form
      :data="data"
      :l="l"
      :columnsDriver="columnsDriver"
      v-on:vehicle="handleVehicle"
      v-on:onsubmit="onSubmit"
    />
  </div>
</template>

<script>
import _ from "lodash";
import { decrypt } from "@/utils/util.js";
import { Toast, Notify } from 'vant';
import Upload from "@/components/Upload.vue";
import Form from "./components/Form.vue";

export default {
  name: 'Oil',
  components: { Upload, Form },
  data() {
    return {
      data: {},
      img: '',
      longitude: '',
      latitude: '',
      visible: false,
      columnsDriver: [],
      l: undefined,
    };
  },
  mounted() {
    let token = sessionStorage.token;
    if (token) {
      this.currentUser = JSON.parse(decrypt(token.substring(token.indexOf('.')+1, token.lastIndexOf('.'))));
      this.l = this.currentUser.l;
    }

    this.loadDriver();
  },
  methods: {
    handleSend(res) {
      this.img = res.url;
      this.longitude = res.lng;
      this.latitude = res.lat;

      Toast.loading({
        message: '识别中...',
        forbidClick: true,
        duration: 0,
      });

      this.$request.updateImg({
        url: res.url,
        type: 1
      }, response => {
        if (response.status === 200) {
          Toast.clear();
          const result = response.data;
          
          if (result.retCode === 200) {
            result.quantity = parseFloat(result.quantity);

            delete result.retCode;
            this.data = result;
            this.visible = false;
          }else{
            this.visible = true;
          }
        }
      }, {
        Authorization: true
      })
    },
    handleVehicle({ plateNo, alias }) {
      this.loadVehicle({ plateNo, alias });
    },
    loadVehicle({ plateNo, alias }) {
      this.$request.queryDriver({ plateNo, alias }, response => {
        if (response.status === 200) {
          const result = response.data;

          if (result) {
            let _data = this.data;
            
            if (result.driver) {
              const { id, driverName, userId } = result.driver;
              _data.driverName = driverName;
              _data.driverId = id;
              _data.driverUserId = userId;
            }
            this.data = { ...result, ..._data };
          }else{
            let data = JSON.parse(JSON.stringify(this.data));
            data.license = plateNo;
            data.alias = alias;
            this.data = data;
          }
        }
      });
    },
    loadDriver() {
      this.$request.queryDrivers({
        companyId: sessionStorage.companyId,
      }, response => {
        if (response.status === 200) {
          const result = response.data;
          result.map(item => {
            let py = pinyinUtil.getFirstLetter(item.name);
            item.py = py.toLowerCase();
            item.text = item.name;
          })
          this.columnsDriver = result;
        }
      });
    },
    onSubmit(values) {
      const { $store, img, longitude, latitude } = this;
      const { license, alias, dateTime } = values;
      values.img = img;
      values.longitude = longitude;
      values.latitude = latitude;

      if (!license && !alias) {
        Toast('车牌号、大号需选填一项');
        return;
      }
      
      if (!dateTime.match(/[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}/g)) {
        Toast('日期格式不正确');
        return;
      }
      
      _.forIn(values, (v, k) => {
        if (!v) delete values[k];
      })
      
      if ($store.state.loading) return;
      $store.commit('setLoading', { loading: true });

      this.$request.putOilTicket({ ...values }, response => {
        if (response.status === 200) {
          const result = response.data;
          $store.commit('setLoading', { loading: false });

          if (result.code === 1) {
            Notify({ type: 'success', message: '保存成功' });
            this.data = {};
            this.img = '';
            this.$router.replace({ path: '/complate', query: { source: 'oil' } });
          }else{
            Notify({ type: 'warning', message: result.msg });
          }
        }
      })
    },
  },
}
</script>


<style lang="scss">
.oil {
  .upload-info {
    background: #fff;
    padding: 12px;
    margin-bottom: 10px;

    .title {
      margin-bottom: 10px;
    }

    .tips {
      width: 50%;
      height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      float: right;

      .error {
        color: $red;
      }

      .success {
        color: $blue;
      }

      span {
        display: block;
        font-size: 16px;
      }
    }
  }
}

.preview-cover {
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
}
</style>
