<template>
  <van-form @submit="onSubmit">
    <van-field
      v-model="dateTime"
      readonly
      clickable
      name="dateTime"
      label="时间选择"
      placeholder="点击选择时间"
      @click="showPicker = true"
    />
      <van-popup v-model:show="showPicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
      />
    </van-popup>

    <van-field
      v-model="quantity"
      type="number"
      label="升"
      required
    />

    <van-field
      v-model="license"
      name="license"
      label="车牌号码"
      placeholder="请输入车牌号码"
      maxlength="7"
    />
    <van-field
      v-model="alias"
      name="alias"
      label="大号"
      placeholder="大号"
    />

    <van-field
      v-if="l < 3"
      v-model="driverName"
      readonly
      clickable
      name="driverName"
      label="驾驶员"
      placeholder="点击选择驾驶员"
      @click="showDriver = true"
    />
    <van-popup v-model:show="showDriver" position="bottom">
      <Picker
        :columns="columnsDriver"
        :activeId="activeId"
        :column="4"
        :step="true"
        :search="true"
        v-on:change="value => columnActiveDriver = value"
      />

      <van-cell>
        <van-button
          round
          block
          type="primary"
          @click="onConfirmDriver"
        >
          确定
        </van-button>
      </van-cell>
    </van-popup>

    <div style="margin: 16px;">
      <van-button :loading="$store.state.loading" round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>


<script>
import moment from "moment";
import Picker from "../../../components/Picker.vue";

export default {
  props: [
    "data",
    "columnsDriver",
    "l",
  ],
  components: { Picker },
  data() {
    return {
      license: '',
      alias: '',
      dateTime: '',
      quantity: undefined,
      showPicker: false,
      driverName: '',
      showDriver: false,
      columnActiveDriver: {},
      minDate: new Date(1960, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
    };
  },
  mounted() {
    const { $watch, $emit } = this;

    $watch('data', (newValue) => {
      const { date, quantity, license, alias } = newValue;

      if (date) {
        this.dateTime = date.replace(/\s+/g, '').replace(/[年月]/g,'-').replace(/[日]/g,' ');
      }
      if (quantity) this.quantity = quantity;
      this.license = license;
      this.alias = alias;
    })
    
    $watch('license', (newValue) => {
      if (newValue && newValue.length == 7) {
        $emit('vehicle', { plateNo: newValue });
      }
    })

    $watch('alias', (newValue) => {
      if (newValue) {
        $emit('vehicle', { alias: newValue });
      }
    })
  },
  methods: {
    onConfirm(time) {
      this.dateTime = moment(time).format('YYYY-MM-DD HH:mm:ss');
      this.showPicker = false;
    },
    onConfirmDriver() {
      const {
        columnActiveDriver: { id, text }
      } = this;
      
      this.driverName = text;
      this.driverId = id;
      this.showDriver = false;
    },
    onSubmit() {
      const {
        license,
        alias,
        dateTime,
        quantity,
        driverName,
        driverId,
      } = this;

      this.$emit('onsubmit', { dateTime, license, alias, quantity, driverName, driverId });
    },
  },
}
</script>
